import { ResponsiveContainer, Treemap, Tooltip, Text } from "recharts"
import { COLORS } from "../../utils/colors"
import Info from "../Cyberdash/Info"

const CustomizedContent = (props) => {
    const { x, y, width, height, index, colors, name, data } = props;

    return (
        <>
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill: colors[index],
                        fontWeight: 400,
                    }}
                />
                {index < 2 ? (
                    <>
                        <text className="treemapText" x={x + width / 2} y={y + height / 3 - 16} textAnchor="middle" fill="#fff" fontSize={16}>
                            Tactic:
                        </text>
                        <text className="treemapText" x={x + width / 2} y={y + height / 3} textAnchor="middle" fill="#fff" fontSize={16}>
                            {data[index]['name'].length > 12 ? data[index]['name'].substring(0, 12) + "..." : data[index]['name']}
                        </text>
                        <text className="treemapText" x={x + width / 2} y={y + height / 3 + 24} textAnchor="middle" fill="#fff" font-weight={"normal"} fontSize={16}>
                            Technique:
                        </text>
                        <text className="treemapText" x={x + width / 2} y={y + height / 3 + 40} textAnchor="middle" fill="#fff" font-weight={"normal"} fontSize={16}>
                            {data[index]['techniques'].length > 12 ? data[index]['techniques'].substring(0, 12) + "..." : data[index]['techniques']}
                        </text>
                        <text className="treemapText" x={x + width / 2} y={y + height / 3 + 66} textAnchor="middle" fill="#fff" fontSize={24}>
                            {data[index]['size']} %
                        </text>
                        <text className="treemapText" x={x + 4} y={y + 16} fill="#fff" fontSize={16} fontWeight={400}>
                            {data[index]['technique_id']}
                        </text>
                    </>
                ) : index >= 2 ? (
                    <>
                        <text className="treemapText" x={x + width / 2} y={y + height / 2 + 16} textAnchor="middle" fill="#fff" fontSize={20}>
                            {data[index]['size']}%
                        </text>
                        <text className="treemapText" x={x + 4} y={y + 16} fill="#fff" fontSize={16} fillOpacity={0.9}>
                            {data[index]['technique_id']}
                        </text>
                    </>
                ) : (
                    null
                )}
            </g>
        </>
    );
}

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">
                    {`${payload[0].payload.technique_id} : ${payload[0].payload.name}, ${payload[0].payload.techniques}`}
                    <br />
                    {payload[0]['value']} %
                </p>
            </div>
        );
    }

    return null;
};

const TreeMap = (props) => {
    const { data, tooltip, title } = props.data

    return (
        <div className="TreemapWrapper">
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <Info description={tooltip} />
            <ResponsiveContainer width="100%" height="100%">
                <Treemap
                    data={data}
                    dataKey="size"
                    stroke="#fff"
                    aspectRatio={3 / 4}
                    content={<CustomizedContent colors={COLORS} data={data} />}
                >
                    <Tooltip content={<CustomTooltip />} />
                </Treemap>
            </ResponsiveContainer>
        </div>
    );
}

export default TreeMap