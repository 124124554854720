import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import { DONUT_COLORS } from "../../utils/colors";
import { useMemo } from "react";
import Info from "./Info";
import useWindowDimensions from "../../hooks/useWindowDimensions/useWindowDimensions";
import { makePercent } from "../../utils/helpers";

function DonutWrapper(props) {
  if (props.data == null) {
    return (
      <div className='DonutWrapper'>
        <p>Null</p>
      </div>
    )
  }

  const { data, title, tooltip } = props.data;
  const pieData = useMemo(() => data.map((d) => {
    let subKey = typeof (d.key) === "string" && d.key.length > 35 ? (d.key.substring(0, 35) + "...") : d.key
    return {
      ...d, sub_key: subKey, value: parseFloat(d.value)
    }
  }),
    [data]
  );
  const { width } = useWindowDimensions();

  return (
    <div className="DonutWrapper" id={props.id}>
      <Info description={tooltip} />
      <header>
        <h2>{title.toUpperCase()}</h2>
      </header>
      <section>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              cx={width > 576 ? "45%" : "50%"}
              innerRadius="60%"
              outerRadius="85%"
              nameKey="key"
              dataKey="value"
              labelLine={false}
              data={pieData}
            >
              {pieData.map((datum, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={DONUT_COLORS[index % DONUT_COLORS.length]}
                  stroke={DONUT_COLORS[index % DONUT_COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              layout={width > 576 ? "vertical" : "horizontal"}
              verticalAlign={width > 576 ? "top" : "bottom"}
              align={width > 576 ? "right" : "middle"}
              width={width > 576 ? "33%" : "100%"}
              iconType="circle"
              payload={pieData.map((datum, index) => ({
                id: datum,
                type: "circle",
                value: `${makePercent(datum.value, 1)} ${datum.sub_key}`,
                color: DONUT_COLORS[index % DONUT_COLORS.length],
              }))}
              formatter={(value) => (
                <span className="legend-value">{value}</span>
              )}
            />
            <Tooltip contentStyle={{ fontSize: 12 }} formatter={(value) => makePercent(value, 1)} />
          </PieChart>
        </ResponsiveContainer>
      </section>
    </div>
  );
}

export default DonutWrapper;
