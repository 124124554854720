import { useMemo } from "react";
import ThreatActorInfo from "../base/ThreatActorInfo";
import Tooltip from "../base/Tooltip";

function shorten_title(title, short, length) {
    if (title == null) {
        return "Not available information"
    } else if (short && title.length > length) {
        return title.substring(0, length) + "..."
    } else {
        return title
    }
}

function clean_data(data) {
    let cleanedData = []

    data.map((d) => {
        let link = d.description.substring(d.description.indexOf("(") + 1, d.description.indexOf(")"));

        let desc = d.description.replace(/ *\([^)]*\) */g, " ")

        cleanedData.push({ modified: d.modified, threat_actor: d.threat_actor, aliases: d.aliases, description: desc, link: link, available_reports: d.available_reports })
    })

    return cleanedData
}

function ThreatActorTable(props) {
    if (props.data == null) {
        return (
            <div className='BigBarWrapper'>
                <p>Null</p>
            </div>
        )
    }

    const { data, title, tooltip } = props.data;
    const cleanedData = useMemo(() => clean_data(data), [data])

    return (
        <div className="Table">
            <ThreatActorInfo />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <section>
                <table>
                    <thead>
                        <tr>
                            <td width="20%">Last Update</td>
                            <td width="20%">Main Name</td>
                            <td width="20%">Aliases</td>
                            <td width="40%">Description</td>
                        </tr>
                    </thead>
                    <tbody>
                        {cleanedData.map((datum, idx) => (
                            <tr key={idx}>
                                <td width="20%">{datum.modified}</td>
                                {datum.available_reports != "none" ? (
                                    <td width="20%" className="logo-row">
                                        <a href={datum.available_reports} target="_blank">{datum.threat_actor}</a>
                                        <img
                                            src="icons/vedere-labs-logo-white-simple.svg"
                                            alt="Vedere Labs company logo"
                                            className="logo blue-logo"
                                        />
                                    </td>
                                ) : (
                                    <td width="20%"><a href={datum.link} target="_blank">{datum.threat_actor}</a></td>
                                )}
                                <Tooltip content={shorten_title(datum.aliases, false, 25)} direction="bottom">
                                    <p width="40%">{shorten_title(datum.aliases, true, 25)}</p>
                                </Tooltip>
                                <Tooltip content={shorten_title(datum.description, false, 60)} direction="bottom">
                                    <p width="40%">{shorten_title(datum.description, true, 60)}</p>
                                </Tooltip>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div >
    );
}

export default ThreatActorTable;