import Tooltip from "../base/Tooltip";
import Info from "../Cyberdash/Info";

function shorten_title(title, short) {
    if (title == null) {
        return "N/A"
    } else if (short && title.length > 85) {
        return title.substring(0, 85) + "..."
    } else {
        return title
    }
}

function CVETable(props) {
    if (props.data.data == null) {
        return (
            <div className='BigBarWrapper'>
                <p>Null</p>
            </div>
        )
    }

    const { data, title, tooltip } = props.data;

    return (
        <div className="CVETable">
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <section>
                <table>
                    <thead>
                        <tr>
                            <td width="10%">Last Seen</td>
                            <td width="10%">CVE ID</td>
                            <td width="50%">CVE Title</td>
                            <td width="10%">CVSS Score</td>
                            <td width="10%">EPSS Score</td>
                            <td width="5%">Is CISA KEV</td>
                            <td width="5%">Number of exploitations</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((datum, idx) => (
                            <tr key={idx}>
                                <td width="10%">{datum.last_seen.split("T")[0]}</td>
                                <td width="10%"><a target={"_blank"} href={"https://nvd.nist.gov/vuln/detail/" + datum.CVE.split(",")[0]}>{datum.CVE}</a></td>
                                <Tooltip content={shorten_title(datum.cve_title, false)} direction="top">
                                    <p width="50%">{shorten_title(datum.cve_title, true)}</p>
                                </Tooltip>
                                <td width="10%">{typeof datum.cvss_score !== "string" ? (datum.cvss_score).toFixed(2) : "N/A"}</td>
                                <td width="10%">{typeof datum.epss_score !== "string" ? (datum.epss_score).toFixed(2) : "N/A"}</td>
                                <td width="5%">{datum.is_cisa_kev ? "Yes" : "No"}</td>
                                <td width="5%">{datum.exploitations ? (datum.exploitations) : "N/A"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div >
    );
}

export default CVETable;
